import logo from 'assets/images/logo/logo.PNG'
import profile from 'assets/images/profile.png'
import homeImage from 'assets/images/home/7.png'
import testResults from 'assets/images/coffmangraph.png'

export const images = {
  logo,
  profile,
  homeImage,
  testResults,
}

export default {}
